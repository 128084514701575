<template>
  <a-modal
    width="620px"
    :dialog-style="{ display: 'none' }"
    :body-style="{ height: 'max-content', minHeight: '480px', minWidth: '620px', display: 'flex', flexDirection: 'column', justifyContent: 'space-evenly', padding:'29px 40px' }"
    :footer="null"
    :visible="visible && !!error.response && !!error.response.data"
    :closable="true"
     @cancel="onClose">
    <h1 class="txt-font-din-bold">{{ $t('components.titles.uploadFailedSymbol', { symbol: '!' }) }}</h1>
    <a-icon type="info-circle" :style="{ fontSize: '83px', color: '#e98300'}" theme="filled"/>
    <error-missing-info v-if="error.response.data.code === 409.7" :languages="languages"/>
    <do-not-match v-else-if="error.response.data.code === 409.8" :languages="languages"/>
    <restricted-fields v-else-if="error.response.data.code === 409.9"/>
    <variable-errors v-else-if="error.response.data.code === 409.10 || error.response.data.code === 409.11" :row-indexes="rowIndexes"/>
    <div class="text-right">
      <a-button type="primary" class="dismiss-btn txt-font-dim-med w25" size="large" @click="onClose">{{ $t('components.labels.dismiss') }}</a-button>
    </div>
  </a-modal>
</template>

<script>
export default {
  name: 'Errors',
  components: {
    errorMissingInfo: () => import('./errorMissingInfo.vue'),
    doNotMatch: () => import('./doNotMatch.vue'),
    restrictedFields: () => import('./restrictedFields.vue'),
    variableErrors: () => import('./variableErrors.vue')
  },
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    error: {
      type: Object,
      default: () => ({})
    },
    onClose: {
      type: Function,
      default: () => {}
    }
  },
  computed: {
    languages() {
      const error = this.error.response.data;
      if (error.code === 409.7) {
        return error.details.missingTranslations;
      }
      if (error.code === 409.8) {
        return error.details.requiredLanguages;
      }
      return [];
    },
    rowIndexes() {
      const error = this.error.response.data;
      if (error.code === 409.10 || error.code === 409.11) {
        return error.details.indexes;
      }
      return [];
    }
  }
};
</script>

<style scoped>
.dismiss-btn {
  border-radius: 1px;
}
</style>
